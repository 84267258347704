/* Import fontova */

@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@400;600&family=Poppins:wght@500&display=swap');

html {
  overflow-x: hidden;
}

.row50 {
  width: 50%;
}

.row70 {
  width: 70%;
}

.row95 {
  width: 95%;
}

.row90 {
  width: 90%;
}

.mlr-auto {
  margin: 0 auto 0 auto;
}

.pt-3 {
  padding-top: 3%;
}

.ptb-30 {
  padding-top: 30px;
  padding-bottom: 30px;
}

.plr-8 {
  padding-left: 8px;
  padding-right: 8px;
}

.mt-15 {
  margin-top: 15vh;
}

.mb-15 {
  margin-bottom: 15vh;
}

.mt-5 {
  margin-top: -5%;
}

.mb-10 {
  margin-bottom: 10px !important;
}

.mb-30 {
  margin-bottom: 30px !important;
}

.mt-6 {
  margin-top: 6vh;
}

.hero {
  background-image: url('https://thecmenu.com/wp-content/uploads/2020/10/Section1_bg_2.png');
  background-size: 'cover';
  padding: 2% 7.5% 0 7.5%;
}

.hero-title {
  line-height: 62px;
  font-size: 52px;
  color: #2d2a2a;
  font-weight: 500;
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
}

.hero-subtitle {
  line-height: 46px;
  font-size: 36px;
  color: #2d2a2a;
  font-family: 'Open Sans', Arial, Helvetica, sans-serif;
  font-weight: 400;
}

.p-content {
  line-height: 26px;
  font-size: 16px;
  color: #777;
  font-family: 'Open Sans', Arial, Helvetica, sans-serif;
  font-weight: 400;
}

.hero-btn-purple,
.hero-btn-purple:hover {
  background-color: #501aa7;
  border-color: #501aa7;
  color: #fff;
  border-radius: 5px;
  font-size: 13px;
  line-height: 18px;
  height: 42px;
  width: 92px;
  font-family: 'Open Sans', Arial, Helvetica, sans-serif;
  font-weight: 600;
  text-transform: uppercase;
}

.hero-btn-gray,
.hero-btn-gray:hover {
  background-color: #fff;
  border-color: #dadada;
  color: #3e3e3e;
  border-radius: 5px;
  font-size: 13px;
  line-height: 18px;
  height: 42px;
  width: 180px;
  font-family: 'Open Sans', Arial, Helvetica, sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  border-width: 2px;
}

.btn-white,
.btn-white:hover {
  background-color: transparent !important;
  border-width: 2px;
  border-color: rgba(255, 255, 255, 0.5);
  color: #fff;
  border-radius: 5px;
  width: 180px;
  height: 42px;
  font-family: 'Open Sans', Arial, Helvetica, sans-serif;
  font-weight: 600;
  text-transform: uppercase;
  font-size: 13px;
  line-height: 18px;
}

.vertical-space-gray {
  background-color: #f1f2f6;
  height: 300px;
}

.section-title {
  line-height: 54px;
  font-size: 44px;
  margin: 0 auto 0 auto;
  color: #2d2a2a;
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
  font-weight: 500;
}

.white-title {
  line-height: 54px;
  font-size: 44px;
  color: #fff;
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
  font-weight: 500;
  padding-right: 6%;
}

.white-content {
  line-height: 26px;
  font-size: 16px;
  color: rgba(255, 255, 255, 0.8);
  font-family: 'Open Sans', Arial, Helvetica, sans-serif;
  font-weight: 400;
}

.purple-bg {
  background-image: url('https://thecmenu.com/wp-content/uploads/2020/10/Section-4_bg.jpg?id=2293');
  background-size: cover;
  height: 500px;
  background-position: center;
  background-repeat: no-repeat;
}

.custom-icons {
  height: 64px;
  width: 64px;
}

.ant-card-meta-title {
  font-size: 20px;
  line-height: 1.4;
  color: #2d2a2a;
  font-family: 'Poppins', Arial, Helvetica, sans-serif;
  font-weight: 600;
}

.ant-card-meta-description {
  color: rgb(119, 119, 119);
  font-family: 'Open Sans', Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: 22.4px;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-moz-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-o-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@-ms-keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.purple-card {
  border-radius: 5px;
}

.purple-card:hover {
  background: linear-gradient(to right, rgb(174, 57, 226), rgb(80, 26, 167) 100%);
  background: -ms-linear-gradient(to right, rgb(174, 57, 226), rgb(80, 26, 167) 100%);
  background: -o-linear-gradient(to right, rgb(174, 57, 226), rgb(80, 26, 167) 100%);
  background: -webkit-linear-gradient(to right, rgb(174, 57, 226), rgb(80, 26, 167) 100%);
  background: -moz-linear-gradient(to right, rgb(174, 57, 226), rgb(80, 26, 167) 100%);
  box-shadow: 2px 3px 14px rgba(0, 0, 0, 0.16);
  color: rgba(255, 255, 255, 0.8);
  animation: fadeIn 1s;
}

.purple-card:hover img {
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(35deg) brightness(104%) contrast(105%);
}

.purple-card:hover .ant-card-meta-title,
.purple-card:hover .ant-card-meta-description {
  color: white;
}

.paketiTable {
  margin-top: 4vh;
}

.paketiTable table,
.paketiTable th,
.paketiTable td {
  text-align: center !important;
}

.paketiTable th {
  border: 0 !important;
}

.paketiTable th {
  text-transform: uppercase !important;
  color: #fff !important;
  font-family: 'Open Sans' !important;
}

.paketiTable tr:nth-child(1) th:nth-child(1) {
  background-color: #fff;
}

.paketiTable tr:nth-child(1) th:nth-child(2) {
  background-color: #7b07af;
  font-weight: 600;
}

.paketiTable tr:nth-child(1) th:nth-child(3) {
  background-color: #570099;
  font-weight: 600;
}

.paketiTable tr:nth-child(1) th:nth-child(4) {
  background-color: #1e0075;
  font-weight: 600;
}

.paketiTable tr:nth-child(2) th {
  font-weight: 600;
  font-size: 24pt;
}

.paketiTable tr:nth-child(3) th,
.paketiTable tr:nth-child(4) th {
  font-weight: 400;
}

.paketiTable tr:nth-child(2) th:nth-child(1),
.paketiTable tr:nth-child(3) th:nth-child(1),
.paketiTable tr:nth-child(4) th:nth-child(1) {
  background-color: rgba(173, 57, 225, 1);
}

.paketiTable tr:nth-child(2) th:nth-child(2),
.paketiTable tr:nth-child(3) th:nth-child(2),
.paketiTable tr:nth-child(4) th:nth-child(2) {
  background-color: rgba(137, 45, 203, 1);
}

.paketiTable tr:nth-child(2) th:nth-child(3),
.paketiTable tr:nth-child(3) th:nth-child(3),
.paketiTable tr:nth-child(4) th:nth-child(3) {
  background-color: rgba(80, 26, 167, 1);
}

.paketiTable td {
  border: 1px solid #444444 !important;
  border-bottom: 1px solid #111111 !important;
  border-right: 1px solid #111111 !important;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.15) !important;
  text-shadow: 0 -1px rgba(0, 0, 0, 0.6) !important;
  color: rgb(208, 208, 208) !important;
  font-family: 'Open Sans' !important;
  font-weight: 400 !important;
}

.paketiTable tr:nth-child(even) td,
.paketiTable tr:nth-child(even):hover td,
.paketiTable tr:nth-child(even) td:hover {
  background-color: #333333;
}

.paketiTable tr:nth-child(odd) td,
.paketiTable tr:nth-child(odd):hover td,
.paketiTable tr:nth-child(odd) td:hover {
  background-color: #272727;
}

.social-icons {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  color: #fff;
  font-size: 22px;
  line-height: 60px;
  display: inline-block;
}

.facebook {
  background-color: #365493;
}

.instagram {
  background-color: #774430;
}

.linkedin {
  background-color: #0274b3;
}

.footer {
  background-image: linear-gradient(to right, rgb(174, 57, 226), rgb(80, 26, 167) 100%);
  background-image: -ms-linear-gradient(to right, rgb(174, 57, 226), rgb(80, 26, 167) 100%);
  background-image: -o-linear-gradient(to right, rgb(174, 57, 226), rgb(80, 26, 167) 100%);
  background-image: -webkit-linear-gradient(to right, rgb(174, 57, 226), rgb(80, 26, 167) 100%);
  background-image: -moz-linear-gradient(to right, rgb(174, 57, 226), rgb(80, 26, 167) 100%);
}
