$_border-radius: 5px;

.heading-banner-image {
  border-radius: $_border-radius;
}

.locations-wrapper {
  padding: 15px;
  background-color: rgb(0, 0, 0);
}

.locations-wrapper .main-menu {
  position: relative;
  display: flex;
  justify-content: center;
  border-radius: $_border-radius;
  background-color: #a61d24;

  & li {
    color: white;
    font-size: 14px;
    text-transform: uppercase;
    font-weight: bold;
  }

  & li .ant-menu-item:hover {
    background-color: unset;
    text-decoration: underline;
  }
}

.ant-menu-item-selected {
  background-color: #d32029 !important;
}

.locations-wrapper .sub-menu {
  border-radius: $_border-radius;
  background-image: -webkit-linear-gradient(left, #2b58ab, #123473 100%);
}

.locations-wrapper .footer {
  display: flex;
  justify-content: space-between;
  border-radius: $_border-radius;
  margin-top: 5px;
  padding: 20px;
  background-image: -webkit-linear-gradient(left, #2b58ab, #123473 100%);
  width: 100%;
  & img {
    max-width: 90px;
  }

  & p {
    text-transform: uppercase;
    color: whitesmoke;
    margin: 0;
  }
}

.customDropdown {
  border-radius: 5px;
  background-image: -webkit-linear-gradient(left, #2b58ab, #123473 100%);
  width: 100%;
  height: 50px;
}

.ant-dropdown-link {
  line-height: 42px;
  color: white;
}

.ant-dropdown-menu {
  background-color: #1765ad;
}

.dnevnePonude .ant-modal-content {
  background-color: transparent !important;
}

.dnevnePonude .ant-modal-close {
  color: white;
  background-color: rgba(43, 88, 171, 0.85) !important;
  border-radius: 50%;
}

.dnevna-ponuda {
  background: linear-gradient(#0d61b3, #17add9) !important;
}

.dnevna-ponuda .ant-menu-item {
  background-color: transparent !important;
}

.dnevna-ponuda .ant-menu-item-selected {
  background-color: transparent !important;
}

.modalTitle::after {
  content: '';
  width: 50px;
  border-bottom: 3px solid #ddd;
  position: fixed;
  left: 35px;
  margin-top: 40px;
}
